import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config/index";
import { urlConstants } from "../../../constants/urlConstants";

export const signupApi=createApi({
    reducerPath:'signupApi',
    baseQuery:fetchBaseQuery({
        baseUrl:config().BASE_URL
    }),
    tagTypes:["SignUp"],
    endpoints:(builder)=>({
        signup:builder.mutation<any,any>({
            query:(signupData)=>({
                url:urlConstants.REGISTER,
                method:'POST',
                body:signupData
            }),
            invalidatesTags:['SignUp']
        })
    })
})

export const {
    useSignupMutation
}=signupApi