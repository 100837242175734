import { Alert } from '@mui/material';
import { useEffect } from 'react';
import { useAppSelector } from '../../base/hooks/hooks';
import { setShowAlert,showAlertInitial } from '../../base/reducer/alertReducer';
import { useDispatch } from 'react-redux';

function CustomAlert() {
    const { showAlert } = useAppSelector((state: any) => state.AlertMessaegReducer)
    const dispatch = useDispatch()
    
    const errorModelClose = () => {
        setTimeout(() => {
            dispatch(setShowAlert(showAlertInitial))
        }, 3000);
    }

    useEffect(() => {
        if (showAlert?.showModel || showAlert?.deleteAlert) {
            errorModelClose()
        }
    }, [showAlert?.showModel, showAlert?.deleteAlert])
    return showAlert?.showModel || showAlert?.deleteAlert ? <Alert severity={showAlert?.type} elevation={1}>{showAlert?.message}</Alert> : null
}
export default CustomAlert
