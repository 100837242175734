import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const bannerApi = createApi({
    reducerPath:'bannerApi',
    baseQuery:fetchBaseQuery({
        baseUrl:config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    tagTypes:['banner'],
    endpoints:(builder)=>({
        getBaners:builder.query<any,any>({
            query:({searchString})=>({
                url:urlConstants.BANNER ,
                method:'GET',
                params:{
                    searchString: searchString,
                }
            }),
            providesTags:['banner']
        }),
        createBanner: builder.mutation<any, any>({
            query: (data) => ({
                url: urlConstants?.BANNER,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['banner']
        }),

        getSingleBanner: builder.query<any, void>({
            query: (id: any) => ({
                url: `${urlConstants?.BANNER}/${id}`,
                method: 'GET'
            }),
            providesTags: ['banner']
        }),

        editBanner: builder.mutation<any, any>({
            query: ({id,formData}:any) => ({
                url: `${urlConstants?.BANNER}/${id}`,
                method: 'PUT',
                body:formData
            }),
            invalidatesTags: ['banner']
        }),
        deletetBanner: builder.mutation<any, any>({
            query: (id:any) => ({
                url: `${urlConstants?.BANNER}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['banner']
        })
    })

})

export const {
useGetBanersQuery,
useGetSingleBannerQuery,
useEditBannerMutation,
useCreateBannerMutation,
useDeletetBannerMutation
}=bannerApi