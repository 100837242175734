export const PATH={
    WELCOMESCREEN : '/',
    LOGIN : '/login',
    REGISTER : '/register',
    CHANNEL_PARTNER : '/channel-partner',
    PROJECTS : '/projects',
    ADD_PROJECTS:'/projects/:type?/:edit?',
    ADDPROJECTS:'/projects/add-project',
    BANNER_TYPE:'/banners/:type?/:edit?',
    ADD_BANNER:'/banners/add-banner',
    BANNER:'/banners',
    USER_UPLOAD:'/users-upload',
    LEADS:'/leads-list',
    ADD_LEADS:'/leads-list/:type?',
    REPORT:'/reports-list'
}